





























import { UsersService } from '@/includes/services/UsersService'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import MessageEditorWithMediaData from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import MessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { plainToInstance } from 'class-transformer'

@Component({
  components: {
    MessageEditorWithMediaInput
  },
})
export default class UserSendMessageModal extends Mixins(InputSetups) {
  @Prop() login!: string

  @Prop() userId!: number

  @Emit()
  reset() {
    return;
  }

  isModalOpen = true

  message = plainToInstance(MessageEditorWithMediaData, {
    text: '',
    pin: false,
    disable_link_preview: false,
    disable_notify: false,
    remove_previous: false,
    protect_content: false,
    remove_after: 0,
    send_after: 0,
    attachments: [],
    buttons: [],
  })

  handleOk() {
    UsersService.sendMessage('tg', {
      board_key: this.$store.state.boardsState.activeBoard?.board,
      message: this.message,
      target: this.userId
    })
      .then(() => {
        successNotification(this.$t('bot_send_message_send_ok').toString())
        this.isModalOpen = false;
      })
      .catch(errorNotification)
  }

  isMounted = false

  mounted() {
    this.$nextTick(() => {
      this.isMounted = true
    })
  }

  created() {
    if (this.$store.state.formsState.forms === null) {
      this.$store.dispatch('getForms')
    }
  }
}
